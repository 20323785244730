/* Navbar.css */

.navbar {
  position: sticky;
  top: 0;
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  z-index: 1000;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.navbar-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 20px;
}

.navbar-search-form {
  display: flex;
  align-items: center;
  flex-grow: 1; /* Allow the search form to take up available space */
  justify-content: flex-end;
}

.navbar-search-input {
  padding: 8px;
  font-size: 0.9rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-right: 5px;
  flex-grow: 1;
  max-width: 250px; /* Set a max width for larger screens */
}

.navbar-search-button {
  padding: 8px 12px;
  font-size: 0.9rem;
  margin: 0px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  white-space: nowrap; /* Prevent the button text from wrapping */
}

/* Image container styles */
/* Image container styles */
.navbar-image-container {
  display: flex;
  justify-content: center;
  background-color: #fff; /* Optional: add background color */
}

.navbar-image-container img {
  width: 100%; /* Make the image responsive */
  max-height: 126px; /* Ensure it does not exceed the height */
  object-fit: cover; /* Crop the image if necessary */
  box-shadow: 0 4px 15px 3px rgba(13, 255, 0, 0.544); /* Green shadow */
  border-radius: 5px; /* Optional: to give rounded corners */
}


/* Adjustments for mobile screens */
@media (max-width: 768px) {
  .navbar-title {
    font-size: 1rem;
  }
  .navbar {
    justify-content: center;
  }
}

/* Adjustments for very small screens */
@media (max-width: 480px) {
  .navbar-search-input {
    padding: 8px;
    font-size: 0.7rem;
  }
  .navbar-search-form {
    width: 40%;
  }

  .navbar-search-button {
    padding: 8px;
    font-size: 0.7rem;
  }
}

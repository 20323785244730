/* Login.css */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background-color: #f5f5f5;
  padding: 20px;
}

.login-form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.login-form h2 {
  margin-bottom: 15px;
}

.instructions {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.instructions ol {
  text-align: left;
  margin-left: 20px;
}

.instructions a {
  color: #007bff;
  text-decoration: none;
}

.instructions a:hover {
  text-decoration: underline;
}

input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #0056b3;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .instructions {
    font-size: 14px;
  }

  input {
    padding: 8px;
  }

  button {
    padding: 8px 16px;
  }
}

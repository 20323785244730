@import "~fluid-player/src/css/fluidplayer.css";

.app {
  font-family: "Baloo Bhaina 2", sans-serif;
  text-align: center;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pagination {
  display: flex;
  justify-content: center;
  font-size: 10px;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  color: #007bff;
  text-decoration: none;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.pagination .active a {
  background: #007bff;
  color: white;
  border-color: #007bff;
}

.pagination .disabled a {
  color: #999;
}

.pagination .disabled a:hover {
  cursor: not-allowed;
}

.back-button {
  margin: 20px;
  padding: 10px 20px;
  border: none;
  background: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.back-button:hover {
  background: #0056b3;
}

.detail-container {
  padding: 20px;
  text-align: left;
}

.video-container video {
  width: 100%;
  max-width: 600px;
}

.images-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.image-wrapper img {
  width: 100%;
  border-radius: 5px;
}

.no-content {
  color: #999;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}

.recommended-section {
  margin-top: 40px;
}

.recommended-section h3 {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cards-container .card {
  margin: 10px;
}

/* General styling for the dropdown container */
.filters {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 10px;
  /* max-width: 600px; */
  gap: 20px;
}

.filterBox{
  display: flex;
  width: 30%;
  justify-content: center;
  flex-direction: column;

}

/* Styling for the label */
.filters label {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

/* Styling for the dropdown */
.filters select {
  width: 100%;
  max-width: 300px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments for mobile and smaller screens */
@media (max-width: 600px) {
  .filters {
    justify-content: center; /* Distribute dropdowns evenly */
  }

  .filters select {
    /* width: 45%; */
  }
  .filters label {
    font-size: 12px;
  }
}

.refresh-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.refresh-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.refresh-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .refresh-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

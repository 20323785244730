.detail-container {
          padding: 20px;
          background: #f0f0f0;
          border-radius: 8px;
          font-size: medium;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        
        .video-container {
          margin-bottom: 20px;
          text-align: center;
        }
        
        .images-container {
          margin-bottom: 20px;
        }
        
        .images-container h2 {
          font-size: 1.5em;
          margin-bottom: 15px;
        }
        
        .images-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
          gap: 10px;
        }
        
        .images-grid img {
          width: 100%;
          height: auto;
          border-radius: 8px;
          object-fit: cover; /* Ensures images cover the area without distortion */
        }
        
        .no-content {
          font-size: 1.2em;
          color: #555;
        }
        
        .title {
          /* font-size: 1.5em; */
          margin-bottom: 20px;
        }
        
        .recommended-section {
          margin-top: 20px;
        }
        
        .recommended-section h3 {
          /* font-size: 1rem; */
          margin-bottom: 10px;
        }
        
        .cards-container {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
        }
        /* Detail.css */

.loading-spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading {
  opacity: 0.5; /* Optional: make the card list semi-transparent during loading */
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

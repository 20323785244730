.marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  background-color: #ccfeff; /* Soft background color for contrast */
  padding: 15px; /* Add padding for spacing */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 8px; /* Rounded corners for a modern look */
  margin: 20px 0; /* Add some margin above and below */
}

.marquee-text {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear infinite; /* Adjust the duration for speed */
  font-size: 18px; /* Adjust font size */
  color: #333;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee-text a {
  color: #1d3557; /* A contrasting color for links */
  font-weight: bold; /* Bold text for emphasis */
  text-decoration: none; /* Remove underline */
  margin-left: 15px; /* Add space between text and link */
}

.marquee-text a:hover {
  color: #457b9d; /* Color change on hover for interaction */
  text-decoration: underline; /* Underline on hover for emphasis */
}

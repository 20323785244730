/* Container for scrolling playlists */
.scrolling-playlists {
  display: flex;
  flex-direction: row;
  overflow-x: auto;       /* Allow manual horizontal scrolling */
  white-space: nowrap;    /* Prevent wrapping of items */
  gap: 10px;
  scroll-behavior: smooth; /* Smooth manual scroll */
}

/* Hide default scrollbar */
.scrolling-playlists::-webkit-scrollbar {
  display: none;
}

.playlist-card {
  flex: 0 0 auto;         /* Prevent wrapping */
  width: auto;
  height: 150px;          /* Increase height to accommodate title */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.1s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;  /* Space between image and title */
}

.playlist-card:hover {
  transform: scale(1.05);
}

.playlist-img {
  width: 100%;
  height: 100%;  /* Occupy 85% of card height */
  object-fit: cover;
  border-radius: 10px;
}

.playlist-title-banner{
  height: 15%;  /* Occupy 15% of card height */
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #969494;
  text-align: center;
  padding: 4px 0;  
  /* Add some padding for spacing */
  background-color: rgba(0, 0, 0, 0.403);
  border-radius: 5px; /* Rounded corners */
  text-transform:none; /* Optional, makes text all uppercase */
}

@media (max-width: 768px) {
  .playlist-card {
    width: auto;
    height: 130px;  /* Adjust card height for smaller screens */
  }
  
  .playlist-img {
    height: 100%;   /* Adjust image height for smaller screens */
  }
}

@media (max-width: 480px) {
  .playlist-card {
    width: auto;
    height: 110px;  /* Adjust card height for mobile screens */
  }
  
  .playlist-img {
    height: 100%;   /* Adjust image height for mobile screens */
  }
}

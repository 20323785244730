.marquee-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    background-color: #ffebcc;
    padding: 0px;
    border-top: 2px solid #e63946;
    border-bottom: 2px solid #e63946;
  }
  
  .marquee-text {
    display: inline-block;
    animation: marquee 30s linear infinite; /* Slow speed for scrolling */
    font-size: 18px;
    color: #333;
    transform: translateX(0); /* Start the text immediately on the screen */
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0); /* Start immediately on the screen */
    }
    100% {
      transform: translateX(-100%); /* Scroll completely off the left side */
    }
  }
  
  .marquee-text a {
    color: #1d3557;
    font-weight: bold;
    text-decoration: none;
    margin-left: 10px;
  }
  
  .marquee-text a:hover {
    color: #457b9d;
    text-decoration: underline;
  }
  
/* Container styles (this should be applied to the parent component holding the cards) */
.cards-container {
  display: flex;
  flex-wrap: wrap; /* Allows multiple cards in one row */
  justify-content: space-between; /* Space between cards */
  padding: 10px;
  gap: 15px; /* Optional: controls spacing between cards */
}

/* Reduced card size */
.card {
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 200px; /* Smaller card width */
  margin: 10px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

/* Reduced card image size */
.card-image {
  width: 100%;
  height: 120px; /* Smaller image height */
  background-size: cover;
  background-position: center;
  position: relative;
}

.content-tag, .premium-tag {
  position: absolute;
  top: 0;
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
  border-bottom-left-radius: 5px;
}

.content-tag {
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.premium-tag {
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.premium-tag.premium {
  background-color: #d84a3a9d; /* Red for Premium */
}

.premium-tag.not-premium {
  background-color: #2ecc71; /* Green for Not Premium */
}

.card-content {
  padding: 10px;
}

.card-category {
  font-size: 0.9em;
  color: #888;
}

.card-title {
  font-size: 14px;
  margin: 5px 0;
}

.card-subtitle {
  font-size: 0.9em;
  color: #555;
}


@media (max-width: 768px) {
  /* Container styles (this should be applied to the parent component holding the cards) */
.cards-container {
  display: flex;
  flex-wrap: wrap; /* Allows multiple cards in one row */
  justify-content: space-between; /* Space between cards */
  padding: 5px;
  gap: 15px; /* Optional: controls spacing between cards */
}

/* Reduced card size */
.card {
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 170px; /* Smaller card width */
  margin: 10px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

/* Reduced card image size */
.card-image {
  width: 100%;
  height: 120px; /* Smaller image height */
  background-size: cover;
  background-position: center;
  position: relative;
}

.content-tag, .premium-tag {
  position: absolute;
  top: 0;
  padding: 5px 10px;
  font-size: 6px;
  color: #fff;
  border-bottom-left-radius: 5px;
}

.content-tag {
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.premium-tag {
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.premium-tag.premium {
  background-color: #d84a3a9d; /* Red for Premium */
}

.premium-tag.not-premium {
  background-color: #2ecc70c1; /* Green for Not Premium */
}

.card-content {
  padding: 5px;
}

.card-category {
  font-size: 6px;
  color: #888;
}

.card-title {
  font-size: 12px;
  margin-top: 2px;
}

.card-subtitle {
  font-size: 0.9em;
  color: #555;
}

}

@media (max-width: 768px) {

  .card{
    max-width: 150px;
  }
}